import { SocialIcons } from "components/SocialIcons";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        <div className="footer__block">
          <StaticImage
            className="footer__logo"
            src="../images/logo.png"
            alt="What's The Motive logo"
          />
        </div>
        <div className="footer__block">
          <a href="mailto:info@whatsthemotiveuk.com">Email</a>
          <a href="tel:+447799084645">Phone</a>
        </div>
        <div className="footer__block">
          <SocialIcons />
        </div>
      </div>
    </footer>
  );
}
