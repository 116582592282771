import { useLocation } from "@reach/router";
import { SocialIcons } from "components/SocialIcons";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useEventListener } from "hooks/useEventListener";
import { useFocusOut } from "hooks/useFocusOut";
import React, { useEffect, useRef, useState } from "react";
import IconCross from "../svgs/IconCross.svg";
import IconMenu from "../svgs/IconMenu.svg";

const links = [
  { to: "/", children: "Events" },
  { to: "/about", children: "About" },
  { to: "/birthday", children: "Birthday" },
];

export function Header() {
  const { pathname } = useLocation();
  const [navIsOpen, setNavIsOpen] = useState(false);
  useEffect(() => setNavIsOpen(false), [pathname]);

  const headerRef = useRef(null);
  useFocusOut(headerRef, () => setNavIsOpen(false));

  useEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setNavIsOpen(false);
    }
  });

  return (
    <header ref={headerRef} id="header" data-open={navIsOpen}>
      <div className="header__content">
        <Link to="/">
          <StaticImage
            className="header__logo"
            src="../images/logo.png"
            alt="What's The Motive logo"
          />
        </Link>
        <nav className="header__nav-desktop">
          {links.map((link) => (
            <Link key={link.to} to={link.to}>
              {link.children}
            </Link>
          ))}
        </nav>
        <SocialIcons />
        <button
          className="header__toggle_nav"
          onClick={() => setNavIsOpen(!navIsOpen)}
          aria-expanded={navIsOpen}
          aria-controls="header"
        >
          {navIsOpen ? <IconCross /> : <IconMenu />}
        </button>
      </div>
      <nav className="header__nav-mobile">
        {links.map((link) => (
          <Link key={link.to} to={link.to} tabIndex={navIsOpen ? 0 : -1}>
            {link.children}
          </Link>
        ))}
      </nav>
    </header>
  );
}
